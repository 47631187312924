import * as React from "react"
import {Grid} from "@material-ui/core";
import MainMenu from "../MainMenu";
import "./../../styles/index.scss"
import Seo from "../seo";
import AppFooter from "../AppFooter";
import {Mixpanel} from "../Mixpanel";
import {useEffect, useMemo} from "react";
import ServiceList from "../ServiceList";
import OpenChat from "../OpenChat";
import Banner from "../Banner";

export default function ComponentServices (props) {
    const {data, pageContext} = props;
    const prismicContact = data.prismicContact
    const allPrismicServices = data.allPrismicServices
    const testimonials = data.allPrismicTestimonial
    const mustOpenChat = new URLSearchParams(props.location.search).get('openChat');

    useEffect(() => {
        Mixpanel.track('Page-Prices');
        if (typeof mustOpenChat != "undefined" && mustOpenChat) {
            if (typeof window != "undefined") {
                window.openZohoChatProgramatically();
            }
        }
    }, [data])

    const seoData = {
        canonical: "https://www.cabinet-psiho.ro/services/",
        title: 'Servicii, Cabinet psiho, Roxana Simionescu psiholog atestat',
        description: 'Servicii, avize, sedinte online, Cabinet psihologic Roxana Simionescu psiholog atestat. Bucuresti psihoterapie, psihologie clinica, avize,  hipnoza,',
        url: "https://www.cabinet-psiho.ro/services/",
        metas: [
        ]
    }

    const renderUsingMemo = () => (
        <div >
            <Seo title="Servicii si Preturi" seoData={seoData}/>
            <Grid container>
                <MainMenu pageName={"servicii_preturi"} prismicContact={prismicContact} />

                <Banner />

                <ServiceList
                    allPrismicServices={allPrismicServices}
                    pageContext={pageContext}
                    showTitle={true}
                    prismicContact={prismicContact}
                    testimonials={testimonials}
                    showSearch={true}
                    showDetails={false}
                />
            </Grid>

            <div className={"w-full flex flex-row justify-content-center align-items-center align-middle mt-16 mb-8"}>
                <OpenChat />
            </div>

            <AppFooter prismicContact={prismicContact}/>
        </div>
    )
    const memoizedValue = useMemo(() => renderUsingMemo(), [prismicContact]);
    return (<>{memoizedValue}</>)
}

