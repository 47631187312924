import * as React from "react"
import { graphql } from 'gatsby'
import ComponentServices from "./../components/loadable/services";

const Component = (props) => {
    return (
        <ComponentServices {...props} />
    )
};

export default Component;


export const query = graphql`
    query Prices {

        prismicContact {
            id
            data {
              first_meeting_is_free
              therapeut_name {
                text
              }
              body {
                ... on PrismicContactDataBodyEmail {
                  id
                  items {
                    display
                    email {
                      text
                    }
                  }
                  slice_label
                  slice_type
                }
                ... on PrismicContactDataBodyLocation {
                  id
                  items {
                    display
                    embeded_map {
                     raw
                    }
                    short_name {
                      text
                    }
                    address {
                      text
                    }
                    latitude {
                      text
                    }
                    longitude {
                      text
                    }
                    image_building {
                      gatsbyImageData
                      alt
                      url
                    }
                  }
                  slice_label
                  slice_type
                }
                ... on PrismicContactDataBodyPhone {
                  id
                  items {
                    display
                    phone_number {
                      text
                    }
                  }
                  slice_label
                  slice_type
                }
                ... on PrismicContactDataBodyProgram {
                  id
                  primary {
                    program {
                      text
                    }
                  }
                  slice_label
                  slice_type
                }
                ... on PrismicContactDataBodyWhasup {
                  id
                  items {
                    display
                    whatsup_phone_number {
                      text
                    }
                    whatsup_desktop_text {
                      text
                    }
                    whatsup_mobile_text {
                      text
                    }
                  }
                  slice_label
                  slice_type
                }
              }
              page_title {
                text
              }
              second_title {
                text
              }
              text_first_meeting_free {
                text
              }
            }
        }
        
        prismicHomepage {
            id
            data {
                header_category {
                    text
                    html
                }
                header_my_name {
                    text
                    html
                }
                header_phone {
                    text
                    html
                }
                header_program {
                    text
                    html
                }
                banner_category {
                    text
                    html
                }
            }
        }
        
        allPrismicTestimonial {
            edges {
                node {
                    id
                    first_publication_date
                    last_publication_date
                    data {
                        author_job {
                            text
                            html
                        }
                        author_name {
                            text
                            html
                        }
                        testimonial_content {
                            text
                            html
                        }
                        author_image {
                            alt
                            copyright
                            url
                            gatsbyImageData
                        }
                    }
                }
            }
        }
        
        allPrismicServices (sort: {order: ASC, fields: data___service_categories___priority}) {
            edges {
                node {
                    id
                    data {
                        priority
                        service_categories {
                            category_image {
                                gatsbyImageData
                            }
                            category_name {
                                text
                                html
                            }
                            description {
                                text
                                html
                            }
                            price {
                                text
                                html
                            }
                            sku {
                                text
                                html
                            }
                        }
                        service_base_categori {
                            text
                            html
                        }
                        is_visibile_on_website
                    }
                }
            }
        }
    }
`
